import React from 'react'
import Helmet from 'react-helmet'

import { FaChevronRight } from 'react-icons/fa'
import { graphql, Link } from 'gatsby'

import './style.scss'
import Layout from '../layouts'
import LocalityBreadCrumb from '../components/LocalityBreadCrumb'

import { cityPath } from '../path'

const CitiesList = ({ data, pathContext }) => (
  <Layout>
    <section className="section">
      <div className="container">
        <LocalityBreadCrumb
          region={pathContext.region}
          department={pathContext.department}
        />
        <Helmet title="Les meilleurs bar de France" />
        <h1 className="title">
          Les meilleurs bars de France
        </h1>
        <div className="content">
          <p>
            Trouvez les meilleurs bars de France avec MisterGoodBeer.
            Tous les bars les moins chers, avec les meilleures happy hours et
            les meilleures bières y sont référencés.
          </p>
        </div>
        <h2>Les villes du département</h2>
        <ul>
          {data.allCitiesJson.edges
            .map((cityNode) => (
              <li key={cityNode.node.name}>
                <Link to={cityPath(cityNode.node)}>
                  {cityNode.node.name}
                  <span className="icon is-large">
                    <FaChevronRight />
                  </span>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </section>
  </Layout>
)

export default CitiesList


export const pageQuery = graphql`
  query DepartmentCities ($departmentCode: String!) {
    allCitiesJson (filter: {department: {code : {eq: $departmentCode}}}){
      edges {
        node {
          name
        }
      }
    }
  }
`
